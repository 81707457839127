import { extend } from 'vee-validate'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const getFormDataFromJSON = object => {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    formData.append(key, object[key])
  })
  return formData
}

const showToast = (title, text, variant = 'success', timeout = 2000) => {
  let icon = 'InfoIcon'
  if (variant === 'success') {
    icon = 'CheckCircleIcon'
  }
  if (variant === 'danger') {
    icon = 'AlertCircleIcon'
  }
  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      icon,
      title,
      text,
      variant,
    },
  }, { timeout })
}

const paginateArray = (array, pageSize, pageNumber) => (array.length ? array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) : [])

const validatorPositive = value => value >= 0

const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

const confirmDeletion = async () => Vue.prototype.$swal({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-danger ml-1',
  },
  buttonsStyling: false,
})

const userHaveRole = (user, role) => {
  if (user.user_has_role && user.user_has_role.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const userHasRoleElement of user.user_has_role) {
      if (role.includes(userHasRoleElement.role.role)) {
        return true
      }
    }
  }
  return false
}
export {
  getFormDataFromJSON, userHaveRole, paginateArray, validatorPositive, positive, showToast, confirmDeletion,
}
